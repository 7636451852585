import React from 'react'
import { useStaticQuery, graphql,Link, Link as GLink} from 'gatsby'
import Img from 'gatsby-image'
import { Box, Card, Flex, Button,Text } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import { FaRegClock,FaCertificate,FaBullhorn } from 'react-icons/fa'


/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  },
  item: {
    display: `inline-block`
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='Courses' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Full Stack Javascript Web Development Course Bangalore'
            subheader=''
          />
          <Text sx={styles.item}>
            {`By `}
            <Link variant='mute' as={GLink} to="/author/ani/">
              <strong>Aniruddha SG</strong>
            </Link>
          </Text>
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button} style={{background: "darkorange", color: "black", borderColor:"darkorange"}}  as={Link} to='/contact'>Contact Us</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
           
            <Box sx={styles.column}>
              <Section title='HERE’S WHAT YOU WILL LEARN!'>
                <Text variant='p'>
                  <ul type="square">
                    <li>Learn to develop front ends of websites with Javascript.</li>
                    <li>Learn Object Oriented Programming in  Javascript.</li>
                    <li>Ensure that the websites you build are dynamic, sustainable, cost-effective, and high on quality and maintainability.</li>
                    <li>Learn how applications are built from scratch. You will get to know how an idea written on paper can be turned into an application used by people.</li>
                  </ul>
                </Text>
              </Section>
              <Divider />
              <Section title='WHAT DO YOU NEED TO BE FAMILIAR WITH?'>
                <Text variant='p'>
                  <ul type="square">
                    <li>Basic Knowledge of Object Oriented Programming language would be beneficial.</li>
                    <li>Prior Knowledge of programming is a plus, however not mandatory.</li>
                    <li>Basics of any database</li>
                    <li>Basic of Front End Designing tools such as HTML, CSS and JavaScript is desirable, however not mandatory.</li>
                  </ul>
                </Text>
              </Section>
              <Divider />
              <Section title='WHO IS THE TARGET AUDIENCE?'>
                <Text variant='p'>
                  <ul type="square">
                    <li>Students who want to have a job worthy skill set.</li>
                    <li>Software Developers who want to get to an intermediate level with a strong understanding of good programming practices.</li>
                    <li>One making a career shift and wanting to become a developer.</li>
                    <li>Experienced Professionals who want to quickly gain mastery over Rails.</li>
                    <li>Entrepreneurs who are not technology savvy will benefit immensely from this course and you will own your technology stack.</li>
                    <li>Web Designers who want to evolve as full stack developers.</li>
                  </ul>
                </Text>
              </Section>
            </Box>             
            <Box sx={styles.column}>
            <Section title='Course Is'>
                <Card variant='paper'>
                  <ul>
                  <p><FaRegClock css={{ verticalAlign: `middle`, marginRight: "10px" }} />Duration: 4 Months</p>
                  <p>
                  <FaBullhorn css={{ verticalAlign: `middle`, marginRight: "10px" }} />Lectures: 150+ Hours Instructor Led + 4 Projects & 150+ Programming Assignments
                  </p>
                  <p>
                  <FaCertificate css={{ verticalAlign: `middle`, marginRight: "10px" }} />Certificate on Project Completion
                  </p>
                  </ul>
                </Card>
            </Section>
            <Divider />
            <Section title='PROMINENT FACTOR'>
                <Card variant='paper'>
                  <ul type="square">
                    <li>Low student to teacher ratio</li>
                    <li>Student specific training based on their abilities</li>
                    <li>Strong in-house community</li>
                    <li>Constant Support</li>
                    <li>Live project based training</li>
                  </ul>
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query CourseQuery {
    photo: file(absolutePath: { regex: "/course1.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
